import { NavLink } from 'react-router-dom';
import './Home.css'; // Assuming you have a Home.css for Home specific styles
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';

function Home() {
    const TRACKING_ID = "G-5B4418Y2D9"; 

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []); 
    return (
        <div className="home-bg">
            {/* Navbar specifically for Home page */}
            <nav className="navbar navbar-expand-lg py-6 home-navbar">
                <div className="container-fluid">
                <NavLink className="navbar-brand custom-brand" to="/">Mayfair Rugs</NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav custom-links">
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active-nav-link"  exact to="/">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link"activeClassName="active-nav-link"  to="/about-us">About</NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active-nav-link" to="/themes">Collections</NavLink>
                        </li>
                        
                        </ul>
                    </div>
                </div>
            </nav>

            <div className='home-page-content'>
            <h1>Weaving <strong>luxury</strong><br/><span className="legacy-text">with legacy</span></h1>
            </div>
                <div className="additional-section">
                <NavLink to="./themes" className="centered-btn">View Collections</NavLink>
            </div>
            <div className='home-area'>
    <h2 className='home-title'>Themes</h2>
    <div className='home-pics'>
            <div className='home'>
            <NavLink to="/collections#fuse">
                <img src='/A3700.png' alt='Fuse' className="theme-image"/>
                <p>Modern</p>
            </NavLink>
        </div>
        <div className='home'>
            <NavLink to="/collections#markazi">
                <img src='/M100.png' alt='markazi' className="theme-image"/>
                <p>Persian</p>
            </NavLink>
        </div>
        <div className='home'>
            <NavLink to="/collections#banjari">
                <img src='/B100.png' alt='banjari' className="theme-image"/>
                <p>Tribal</p>
            </NavLink>
        </div>
        <div className='home'>
            <NavLink to="/collections#baqiara">
                <img src='/BQ100.png' alt='baqiara' className="theme-image"/>
                <p>Kashmiri</p>
            </NavLink>
        </div>
        <div className='home'>
            <NavLink to="/collections#qismat">
                <img src='/Q100.png' alt='qismat' className="theme-image"/>
                <p>Pure Silk</p>
            </NavLink>
        </div>
        <div className='home'>
            <NavLink to="/collections#mixbag">
                <img src='/X100.jpg' alt='mixbag' className="theme-image"/>
                <p>Extra Large</p>
            </NavLink>
        </div>
    </div>
</div>


        </div>
    );
}

export default Home;
