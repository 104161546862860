import { Link } from 'react-router-dom';
import './themes.css';
import Navbar from '/Users/adityasharma/Documents/React/mayfairrugs/src/components/Navbar';
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
function Themes() {
    const TRACKING_ID = "G-5B4418Y2D9"; 

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []); 

    const themes = [
        { id: 1, name: "fuse", image: "/fuse.png" },
        { id: 2, name: "markazi", image: "/markazi.png" },
        { id: 3, name: "banjari", image: "/banjari.png" },
        { id: 4, name: "baqiara", image: "/Baqiara.png" },
        { id: 5, name: "qismat", image: "/qismat.png" },
        { id: 6, name: "mixbag", image: "/mix_bag.png" },
    ];

    return (
        <div className="themes-container">
            <Navbar/>
            {themes.map(theme => (
               <div key={theme.id} className="theme" style={{ backgroundImage: `url(${theme.image})` }}>
                   <Link to={`/collections#${theme.name}`} className="theme-name">{theme.name}</Link>
               </div>
            ))}
        </div>
    );
}

export default Themes;
