import React, { useEffect } from 'react'; // import useEffect here
import Navbar from '../components/Navbar'; // use relative import for components within your project
import ReactGA from 'react-ga';

function ContactUs() {
    const TRACKING_ID = "G-5B4418Y2D9"; // Your tracking ID

    useEffect(() => {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []); 

    return (
        <div>
            <Navbar />
            <h1>Contact Us</h1>
            <p>Welcome to our website!</p>
        </div>
    );
}

export default ContactUs;
