import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Collections from './pages/Collections';
import Themes from './pages/themes';
import Weavers from './pages/Weavers';
import Trade from './pages/Trade';
import ContactUs from './pages/ContactUs';
import Footer from './pages/Footer'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/Collections" element={<Collections />} />
                <Route path="/themes" element={<Themes/>} />
                <Route path="/weavers" element={<Weavers />} />
                <Route path="/trade" element={<Trade />} />
                <Route path="/contact-us" element={<ContactUs />} />
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;
