import Navbar from '/Users/adityasharma/Documents/React/mayfairrugs/src/components/Navbar';
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
function Trade() {
    const TRACKING_ID = "G-5B4418Y2D9"; 

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []); 
    return (
        <div>
            <Navbar/>
            <h1>Trade</h1>
            <p>Welcome to our website!</p>
        </div>
    );
}

export default Trade;
