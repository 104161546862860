import React, { useState, useRef, useEffect} from 'react';
import './design.css';
import Navbar from '/Users/adityasharma/Documents/React/mayfairrugs/src/components/Navbar';
import ReactGA from 'react-ga';
function Collections() {
    const [selectedProduct, setSelectedProduct] = useState({product: null, imageIndex: 0});
    const [selectedSize, setSelectedSize] = useState("Medium");
    const zoomLens = useRef(null);
    const zoomResult = useRef(null);
    const enlargedImgRef = useRef(null);
    
    const TRACKING_ID = "G-5B4418Y2D9"; 
    useEffect(() => {

        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        if (selectedProduct.product) {
            enlargedImgRef.current.addEventListener('mousemove', moveLens);
            zoomLens.current.addEventListener('mousemove', moveLens);
        }

        return () => {
            if (enlargedImgRef.current) {
                enlargedImgRef.current.removeEventListener('mousemove', moveLens);
            }
            if (zoomLens.current) {
                zoomLens.current.removeEventListener('mousemove', moveLens);
            }
        };
    }, [selectedProduct]);

    useEffect(() => {
        // Check for a hash in the URL (e.g., "#fuse")
        const { hash } = window.location;
        if (hash) {
            // Remove the '#' from the hash
            const id = hash.slice(1);
            // Try to find the element with the given ID
            const element = document.getElementById(id);
            if (element) {
                // Get the position of the element
                const top = element.offsetTop;
                // Scroll to the element's position
                window.scrollTo(0, top);
            }
        }
    }, []);

    
    const moveLens = (e) => {
        e.preventDefault();
    
        const pos = getCursorPos(e);
        let x = pos.x - (zoomLens.current.offsetWidth / 2);
        let y = pos.y - (zoomLens.current.offsetHeight / 2);
    
        const lensWidth = zoomLens.current.offsetWidth;
        const lensHeight = zoomLens.current.offsetHeight;
        const imgWidth = enlargedImgRef.current.offsetWidth;
        const imgHeight = enlargedImgRef.current.height;
    
        // Adjustments for left and right boundaries
        if (x > imgWidth - lensWidth) {
            x = imgWidth - lensWidth;
        }
        if (x < 0) x = 0;
    
        if (y > imgHeight - lensHeight) {
            y = imgHeight - lensHeight;
        }
        if (y < 0) y = 0;
    
        if (zoomLens.current) {
            zoomLens.current.style.left = 80 + "px";  // Fixed the missing assignment here
            zoomLens.current.style.top = 410 + "px";
            zoomLens.current.style.display = "block";
        }
    
            const zoomRatio = enlargedImgRef.current.offsetWidth / imgWidth;
            zoomLens.current.style.backgroundImage = `url(${selectedProduct.product.image[selectedProduct.imageIndex]})`;
            zoomLens.current.style.backgroundSize = (imgWidth * zoomRatio) + "px " + (imgHeight * zoomRatio) + "px";
            zoomLens.current.style.backgroundPosition = "-" + (x * zoomRatio) + "px -" + (y * zoomRatio) + "px";
    };
    
    const getCursorPos = (e) => {
        e = e || window.event;
        const a = enlargedImgRef.current.getBoundingClientRect();
        const x = e.pageX - a.left - window.pageXOffset; 
        const y = e.pageY - a.top - window.pageYOffset;
        return { x: x, y: y };
    };
    
 

    const themes = [

        {id: 'fuse',
        name: 'fuse',
        description: 'The fuse collection of rugs offers the most exquisite abstract, transitional and contemporary designs infused in the craft using traditional method of hand-knotting. We are proud to adorn your homes with a blend of modern elegance with timeless handcraft.',
        products: [
        { id: "A100", image: ['/A100.png'], description: 'Fuse - A100', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 6400,"9'x12'": 8640 }, deatailedDescription:'Add a touch of elegance and sophistication to your space with this hand-knotted rug made of wool and bamboo silk. This rug features an abstract pattern with a color palette of blue, gray, and white, creating a stunning visual effect. The rug has a distressed and faded look that adds to its charm and character.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A200', image: ['/A200.png'], description: 'Fuse - A200', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },deatailedDescription:'The fuse collection of rugs offers modern elegance with timeless cratt of hand-knotting. The features an mottled design in blue, gray, and brown colors, creating a contemporary look that complements any decor. The rug is made of wool and bamboo silk that feels cozy under your feet.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A300', image: ['/A300.png'], description: 'Fuse - A300', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Bring home the charm of nature with this hand-knotted rug made of wool and bamboo silk. This rug showcases a floral pattern with trees, flowers, and birds in shades of blue, brown, and green. The rug has a distressed look with faded colors that create a vintage appeal. The wool and bamboo silk blend makes this rug soft, cozy, and resilient.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Constructinon: "Hand knotted"} },
        { id: 'A400', image: ['/A400.png'], description: 'Fuse - A400', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Give your home a unique and cozy vibe with this abstract area rug. This rug has a gray color scheme with splashes of blue and red, creating a dynamic and artistic look. The rug has a distressed and faded appearance that adds character and charm.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted" }},
        { id: 'A500', image: ['/A500.png'], description: 'Fuse - A500', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Transform your space with this modern abstract rug. This rug has a blue and gray color scheme that creates a cool and calm atmosphere. The rug has a distressed and faded look that adds texture and interest. The rug features a variety of geometric and organic patterns that create a dynamic and artistic look.',tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}  },
        { id: 'A600', image: ['/A600.png'], description: 'Fuse - A600', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 6400,"9'x12'": 8640 },detailedDescription:'This handknotted rug is a masterpiece of modern art that will transform any space. It is made with wool and bamboo silk, which are natural and eco-friendly materials that offer a smooth and shiny feel. The rug features a collage of text and images in various colors, inspired by the Japanese technique of Shibori. The collage has a chaotic and abstract look that creates a sense of movement and energy. This handknotted rug is a perfect choice for anyone who loves creative and artistic designs.',tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A700', image: ['/A700.png'], description: 'Fuse - A700', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Bring some vintage charm to your home with this worn and faded rug. This rug has a gray background with an abstract design in blue and brown colors. The design is made up of curved lines and shapes that create a whimsical and artistic look. The rug is frayed around the edges and has some areas where the design is faded or worn away, adding to its character and appeal.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}  },
        { id: 'A800', image: ['/A800.png'], description: 'Fuse - A800', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Enhance your home with this abstract area rug. This rug has a gray color scheme with blue and white accents that create a marbled effect. The rug features a mix of organic and geometric shapes that add visual interest and style. The rug has a distressed, vintage look that adds character and charm.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A900', image: ['/A900.png'], description: 'Fuse - A900', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 6400,"9'x12'": 8640 },detailedDescription:'This hand-knotted rug made of wool and bamboo silk is a stunning piece of art for your floor. The rug features a large white and orange shape in the center, made up of small dots and lines, creating a textured appearance. The shape contrasts with the gray background, giving the rug a modern and artistic look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}  },
        { id: 'A1000', image: ['/A1000.png'], description: 'Fuse - A1000', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Create a stunning focal point in your home with this modern abstract rug. This rug has a distressed, weathered look that adds texture and charm. The colors of the rug are predominantly gray and white, with some areas of blue that create a contrast. The pattern of the rug is abstract and appears to be a combination of brushstrokes and drips, creating a dynamic and artistic look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A1100', image: ['/A1100.png'], description: 'Fuse - A1100', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 5600,"9'x12'": 7560},detailedDescription:'Modern abstract rug that will add a splash of color and style to your home. This rug has a blue and gray color scheme with some white and black accents. The rug has a vertical rectangle shape with a rough texture. The rug has a mix of vertical and horizontal brushstrokes that create a dynamic and artistic look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A1200', image: ['/A1200.png'], description: 'Fuse - A1200', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Modern abstract rug that will add a touch of elegance and sophistication to your home. This rug has a gray, black and white color scheme with hints of blue that create a contrast. The rug has a distressed and weathered look with a mix of different patterns and textures that add visual interest and style. The rug is made of a soft material and has a plush pile that feels cozy under your feet.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A1300', image: ['/A1300.png'], description: 'Fuse - A1300', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Modern abstract rug that will add a splash of color and style to your home. This rug has a gray background with patches of blue, orange, and red scattered throughout. The rug has a distressed look with a faded appearance, giving it a vintage feel.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A1400', image: ['/A1400.png'], description: 'Fuse - A1400', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 8400,"9'x12'": 11340 },detailedDescription:'Modern abstract rug that will add a touch of elegance and sophistication to your home. This rug has a gray and white color scheme with some black accents. The rug has a mix of geometric and organic shapes that create a dynamic and artistic look. The rug is hand-knotted using soft and durable material that feels cozy under your feet.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
        { id: 'A1500', image: ['/A1500.png'], description: 'Fuse - A1500', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Transform your space with this gray and blue abstract hand-knotted rug. This rug features a gray background with blue accents in the form of lines and splotches.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A1600', image: ['/A1600.png'], description: 'Fuse - A1600', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 6400,"9'x12'": 8640 },detailedDescription:'This handknotted rug is a stunning piece of art that will add elegance and style to any space. It is made with wool and bamboo silk, which are natural and durable materials that offer a soft and luxurious feel. The rug features an abstract painting in gray and orange hues, inspired by the Japanese technique of Shibori. The painting has a central figure that resembles a creature with wings, creating a sense of mystery and intrigue. The rug has a textured appearance with splatters and drips of paint, giving it a unique and artistic look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A1700', image: ['/A1700.png'], description: 'Fuse - A1700', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 8400,"9'x12'": 11340 },detailedDescription:'Add a touch of elegance and sophistication to your home with this handknotted rug made with wool and bamboo silk. This rug features a red and white pattern that is inspired by traditional oriental designs. The rug has a distressed and faded look that adds character and charm to any space. The wool and bamboo silk blend is soft, durable, and eco-friendly.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A1800', image: ['/A1800.png'], description: 'Fuse - A1800', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 6400,"9'x12'": 8640 },detailedDescription:'Add a touch of modern art to your home with this unique rug. This rug features a gray canvas with a red line and a splattered paint pattern. The rug is hand-knotted using high-quality wool and bamboo silk.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A1900', image: ['/A1900.png'], description: 'Fuse - A1900', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Bring some warmth and coziness to your space with this modern abstract rug. This rug features a gray background with orange and blue stripes that have a distressed, weathered look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2000', image: ['/A2000.png'], description: 'Fuse - A2000', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Create a cozy and inviting atmosphere with this blue and gray abstract rug. This rug features a distressed and faded look with a pattern of vertical and horizontal lines in varying shades of blue and gray.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2100', image: ['/A2100.png'], description: 'Fuse - A2100', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Enhance your space with this gray and blue abstract rug. This rug features an mottled design with a mix of different patterns and textures, with some orange and brown accents.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2200', image: ['/A2200.png'], description: 'Fuse - A2200', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Add a touch of modern elegance to your space with this abstract area rug. This rug features a distressed gray background with blue and red accents, creating a dynamic and eye-catching pattern.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2300', image: ['/A2300.png'], description: 'Fuse - A2300', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This handspun wool and bamboo silk rug is a stunning addition to any room. The rug has a unique abstract pattern in blue, gray, and white, creating a soothing and sophisticated look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2400', image: ['/A2400.png'], description: 'Fuse - A2400', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This modern abstract rug will add a splash of color and style to your home. The rug has a gray background with a blue and dark gray abstract pattern that resembles a cityscape.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2500', image: ['/A2500.png'], description: 'Fuse - A2500', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This modern abstract rug is a stunning piece of art for your floor. It features a textured surface with grey and white tones and pops of blue and orange. The rug is made of premium wool and bamboo silk and hand-knotted for durability and comfort.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2600', image: ['/A2600.png'], description: 'Fuse - A2600', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This modern abstract rug is a stunning piece of art for your floor. It features a textured surface with grey and white tones and pops of blue and orange. The rug is made of premium wool and bamboo silk and hand-knotted for durability and comfort.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2700', image: ['/A2700.png'], description: 'Fuse - A2700',sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This rug is a masterpiece of modern abstract art. It features a grey background with vibrant red and orange splashes that create a dynamic and eye-catching effect. The rug is hand-knotted with a rough and distressed technique that gives it a unique and edgy look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2800', image: ['/A2800.png'], description: 'Fuse - A2800', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 8400,"9'x12'": 11340 },detailedDescription:'This rug is a gorgeous blend of blue and gray colors with a vintage flair. It features a medallion and floral pattern that adds elegance and charm to any room. The rug is hand-knotted from wool and handspun bamboo silk', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A2900', image: ['/A2900.png'], description: 'Fuse - A2900', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This rug is a chic and modern addition to your home. It features a gray and white abstract design with horizontal stripes and a subtle pattern. The rug is made of a soft and plush material that feels cozy under your feet.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3000', image: ['/A3000.png'], description: 'Fuse - A3000', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This rug is a stunning piece of abstract art for your floor. It features a vertical composition of blue, orange, gray, yellow, and white colors, creating a textured and layered effect. The rug is hand-knotted with a distressed and weathered technique that gives it a unique and edgy look.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3100', image: ['/A3100.png'], description: 'Fuse - A3100', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This rug is a beautiful combination of gray and brown colors with an abstract design. It features a gray base with brown and white accents that create a lively and modern look. The rug is hand-knotted from wool and has a distressed and weathered appearance that adds character and charm to any room.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3200', image: ['/A3200.png'], description: 'Fuse - A3200',sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'Add a touch of elegance and sophistication to your home with this gray and blue abstract rug. This rug is handknotted from a soft and durable blend of handspun wool and bamboo silk, giving it a luxurious feel and a distressed look. The abstract pattern is inspired by the beauty of nature and creates a soothing and serene atmosphere in any room.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3300', image: ['/A3300.png'], description: 'Fuse - A3300', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This modern abstract painting rug is a perfect choice for adding some artistic flair to your home. This rug is handknotted from high-quality wool and handspun bamboo silk yarn, giving it a soft and cozy feel. The rug features a textured surface imitating brushstrokes, creating a stunning visual effect. The rug has a gray background with patches of blue, orange, and yellow, adding a splash of color to any room.'},
        { id: 'A3400', image: ['/A3400.png'], description: 'Fuse - A3400', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 6400,"9'x12'": 8640 },detailedDescription:'This gray and blue geometric rug is a stylish and modern addition to your home. This rug is handknotted from high-quality handspun wool and handspun bamboo silk, giving it a soft and durable feel. The rug features a geometric design with overlapping rectangles and squares, creating a dynamic and eye-catching pattern. The rug has a distressed or faded look, adding a touch of vintage charm to any room. '},
        { id: 'A3500', image: ['/A3500.png'], description: 'Fuse - A3500',sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 8400,"9'x12'": 11340 },detailedDescription:'Bring the beauty of nature into your home with this nature-inspired handknotted rug. This rug is made of high-quality wool and bamboo silk, giving it a soft and cozy feel. The rug features a symmetrical pattern of trees, animals, and other nature-inspired elements in various colors, creating a charming and whimsical look. The rug has a gray background and a border of small trees and animals, adding contrast and balance to the design. The rug has a distressed or faded look, giving it a vintage or antique feel.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3600', image: ['/A3600.png'], description: 'Fuse - A3600', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 4800,"9'x12'": 6480 },detailedDescription:'This gray and white striped rug is a simple and elegant choice for your home. This rug is handknotted from a plush and soft material, giving it a cozy and comfortable feel. The rug features a horizontal striped pattern with varying shades of gray and white, creating a subtle and sophisticated look. The rug has a distressed and weathered look, adding a touch of vintage charm to any room.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3700', image: ['/A3700.png'], description: 'Fuse - A3700', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 8400,"9'x12'": 11340 },detailedDescription:'This red and white floral rug is a beautiful and elegant choice for your home. This rug is handknotted from high-quality wool, giving it a soft and durable feel. The rug features a red base with a white floral pattern on top, creating a contrast and balance. The rug has a distressed or faded look, with some areas of the red base showing through more prominently, adding a touch of vintage charm to any room. The edges of the rug are frayed, giving it a vintage or antique feel.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3800', image: ['/A3800.png'], description: 'Fuse - A3800', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 16000,"9'x12'": 21600 },detailedDescription:'This vintage medallion rug is a stunning piece of art that will add a touch of elegance and charm to your home. This rug is handknotted from a blend of wool and real silk, giving it a soft and luxurious feel. The rug features a central medallion with a red center and a blue border, surrounded by a cream-colored field with a floral design. The rug has a red and black striped border with a cream-colored outer border, creating a contrast and balance. The rug has a faded and worn appearance, giving it a vintage or antique feel.', tiles:{Pile: "70% New Zealand Wool, 30% Real Silk", Warp: 'Wool', Construction: "Hand knotted"}},
        { id: 'A3900', image: ['/A3900.png'], description: 'Fuse - A3900', sizes: ["8'x10'","9'x12'" ], prices:{"8'x10'": 8400,"9'x12'": 11340 },detailedDescription:'This blue and gray transitional rug is a timeless and elegant choice for your home. This rug is handknotted from a blend of wool and bamboo silk, giving it a soft and lustrous feel. The rug features a traditional, ornate design with a central medallion and repeating patterns throughout, creating a sophisticated and classic look. The rug has a faded, vintage look, adding a touch of charm and character to any room.', tiles:{Pile: "60% New Zealand Wool, 40% Bamboo Silk", Warp: 'Wool', Construction: "Hand knotted"}}
        
    ]
    },
    {
        id: 'markazi',
        name: 'markazi',
        description: 'The exquisite Kashan and Nain collection with central (Markazi) motif with intricately knotted designs around the field and the border area. These traditional rugs have been hand-knotted with delicate patience and are available in both Silk and Wool piles.',
        products: [
            { id: 'M100', image: [ '/M100.png', '/M101.png','/M102.png'], description: 'Markazi - M100',sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ], prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560 }, detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with new zealand wool and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'M200', image: ['/M200.png', '/M201.png', '/M202.png'], description: 'Markazi - M200',sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ],  prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560 },detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with new zealand wool and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"} },
            { id: 'M300', image: ['/M300.png', '/M301.png', '/M302.png'], description: 'Markazi - M300',sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ],  prices:{"4'x6'": 2640, "6'x9'":5940, "8'x10'": 8800,"9'x12'": 11880 },detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with new zealand wool and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'M400', image: ['/M400.png', '/M401.png', '/M402.png'], description: 'Markazi - M400',sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'", "10'*14'" ],  prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560, "10'*14'":15400 },detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with new zealand wool and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'M500', image: ['/M500.png', '/M501.png', '/M502.png'], description: 'Markazi - M500', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ],prices:{"4'x6'": 2640, "6'x9'":5940, "8'x10'": 8800,"9'x12'": 11880 }, detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with new zealand wool and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'M600', image: ['/M600.png', '/M601.png', '/M602.png'], description: 'Markazi - M600',sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ],prices:{"4'x6'": 7200, "6'x9'":16200, "8'x10'": 24000,"9'x12'": 32400 }, detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with finest silk and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "Real Silk", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'M700', image: ['/M700.png', '/M701.png', '/M702.png'], description: 'Markazi - M700', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ],prices:{"4'x6'": 7200, "6'x9'":16200, "8'x10'": 24000,"9'x12'": 32400 }, detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with finest silk and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Kashan design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "Real Silk", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'M800', image: ['/M800.png', '/M801.png', '/M802.png'], description: 'Markazi - M800', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'" ],prices:{"4'x6'": 7200, "6'x9'":16200, "8'x10'": 24000,"9'x12'": 32400 }, detailedDescription:'This handknotted rug is a beautiful piece of art that will enhance any space. It is made with finest silk and cotton, which are high-quality and natural materials that offer a soft and cozy feel. The rug features a traditional Persian Nain design in red, blue and gold, with a central medallion and intricate floral and geometric patterns. The rug has a fringe on the edges that adds charm and character. This handknotted rug is a perfect choice for anyone who loves classic and elegant style.', tiles:{Pile: "Real Silk", Warp: 'Indian Cotton', Construction: "Hand knotted"}}
            // ... other products for theme 2
        ]
    },
    {
        id: 'banjari',
        name: 'banjari',
        description: 'The nomadic (Banjari) tribes of ancient lands roamed across rugged terrains, and these rugs embody the spirit of their journeys. It draws inspiration from the rich tapestry of tribal cultures, where repetitive edged motifs and diamond patterns are imbued with symbolism and meaning. These rugs anchor your space, infusing it with the warmth and comfort of 100% premium wool.',
        products: [
            { id: 'B100', image: [ '/B100.png', '/B101.png','/B102.png'], description: 'Banjari - B100', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'", "10'*14'" ],  prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560, "10'*14'":9800}, detailedDescription:'Add a touch of elegance to your home with this handknotted rug made with 100% New Zealand wool. This rug features a stunning red and black geometric pattern in the center, surrounded by a black border', tiles:{Pile: "New Zealand Wool", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
            { id: 'B200', image: ['/B200.png', '/B201.png', '/B202.png'], description: 'Banjari - B200', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560}, detailedDescription:'Brighten up your space with this handknotted rug made with 100% New Zealand wool. This rug has a vibrant orange and red color scheme and a geometric pattern that adds a modern flair. ', tiles:{Pile: "New Zealand Wool", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
            { id: 'B300', image: ['/B300.png', '/M301.png','/B302.png'], description: 'Banjari - B300', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560,}, detailedDescription:'This handknotted rug is a stylish and sophisticated addition to any home. It is made with 100% New Zealand wool, which is a natural and high-quality material that offers a soft and cozy feel. The rug features a beige background with a repeating pattern of brown and black geometric shapes, creating a modern and elegant look. The border of the rug is a darker beige with a repeating pattern of orange and black geometric shapes, adding a touch of contrast and interest. The rug has a fringe on the edges and a textured appearance that enhances its charm and character. This handknotted rug is a perfect choice for anyone who loves contemporary and chic designs.', tiles:{Pile: "New Zealand Wool", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
            { id: 'B400', image: ['/B400.png', '/B401.png', '/B402.png'], description: 'Banjari - B400', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 1680, "6'x9'":3780, "8'x10'": 5600,"9'x12'": 7560}, detailedDescription:'Add a touch of elegance and style to your home with this handknotted rug made with 100% New Zealand wool. This rug features a black and white geometric pattern with a red and white striped border, creating a striking contrast. The rug is soft, durable, and easy to maintain, making it a perfect choice for any room.', tiles:{Pile: "New Zealand Wool", Warp: 'New Zealand Wool', Construction: "Hand knotted"}},
            { id: 'B500', image: ['/B500.png', '/B501.png', '/B502.png'], description: 'Banjari - B500', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 2640, "6'x9'":5940, "8'x10'": 8800,"9'x12'": 11880}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted tribal designed rug made with 100% New Zealand wool. This rug features a stunning Persian design with a central medallion and four corner medallions in shades of blue, red, and gold. The rug is durable, soft, and easy to maintain.', tiles:{Pile: "New Zealand Wool", Warp: 'New Zealand Wool', Construction: "Hand knotted"}}
        ]
    },
    {
        id: 'baqiara',
        name: 'baqiara',
        description: 'Our "Baqiara" collection draws its essence from the crafters of the earth, the gardeners (Baqiara). The geometric boxes within the design symbolize the pedantically cultivated plots of these gardens, each box a testament to the gardeners artistry. With "Baqiara" beneath your feet you are strolling through the verdant terraces, feeling the gentle zephyrs of the Jhelum River, and inhaling the intoxicating aroma of saffron fields.',
        products: [
            { id: 'BQ100', image: [ '/BQ100.png', '/BQ101.png','/BQ102.png'], description: 'Baqiara - BQ100', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 2640, "6'x9'":5940, "8'x10'": 8800,"9'x12'": 11880}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted Kashmiri designed rug made with New Zealand wool. The rug is soft, durable, and easy to maintain, making it a perfect choice for any room. Whether you want to create a cozy atmosphere, a sophisticated look, or a vibrant accent, this rug will suit your style and taste.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"} },
            { id: 'BQ200', image: ['/BQ200.png', '/BQ201.png', '/BQ202.png'], description: 'Baqiara - BQ200', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 2640, "6'x9'":5940, "8'x10'": 8800,"9'x12'": 11880}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted Kashmiri designed rug made with New Zealand wool. The rug is soft, durable, and easy to maintain, making it a perfect choice for any room. Whether you want to create a cozy atmosphere, a sophisticated look, or a vibrant accent, this rug will suit your style and taste.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'BQ300', image: ['/BQ300.png', '/BQ301.png','/BQ302.png'], description: 'Baqiara - BQ300', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 2640, "6'x9'":5940, "8'x10'": 8800,"9'x12'": 11880}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted Kashmiri designed rug made with New Zealand wool. The rug is soft, durable, and easy to maintain, making it a perfect choice for any room. Whether you want to create a cozy atmosphere, a sophisticated look, or a vibrant accent, this rug will suit your style and taste.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'BQ400', image: ['/BQ400.png', '/BQ401.png', '/BQ402.png'], description: 'Baqiara - BQ400', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 9600, "6'x9'":21600, "8'x10'": 32000,"9'x12'": 43200}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted Kashmiri designed rug made with finest Real silk yarn. The rug is soft, durable, and easy to maintain, making it a perfect choice for any room. Whether you want to create a cozy atmosphere, a sophisticated look, or a vibrant accent, this rug will suit your style and taste.', tiles:{Pile: "Kashmiri Silk", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'BQ500', image: ['/BQ500.png', '/BQ501.png', '/BQ502.png'], description: 'Baqiara - BQ500', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 7200, "6'x9'":16200, "8'x10'": 24000,"9'x12'": 32400}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted Kashmiri designed rug made with finest Real silk yarn. The rug is soft, durable, and easy to maintain, making it a perfect choice for any room. Whether you want to create a cozy atmosphere, a sophisticated look, or a vibrant accent, this rug will suit your style and taste.', tiles:{Pile: "Kashmiri Silk", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
        ]
    }, 
    {
        id: 'qismat',
        name: 'qismat',
        description: 'In the heart of the enchanting town of Kashan, where destiny weaves its intricate tapestry of life, we bring you our "Qismat" collection. "Qismat" rugs are not just pieces of art; they are destinies intertwined with your home, destined to bring opulence and charm to your living spaces. With this exclusive pure silk collection, discover the threads of fate and let the beauty of destiny adorn your life.',
        products: [
            { id: 'Q100', image: [ '/Q100.png', '/Q101.png','/Q102.png'], description: 'Qismat - Q100', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 11760, "6'x9'":26460, "8'x10'": 39200,"9'x12'": 52920}, detailedDescription:'This handknotted Kashan designed rug is a masterpiece of Persian craftsmanship. Made with pure fine silk, this rug has a stunning red and gold colour scheme and a central medallion that draws the eye. The floral design is intricate and elegant, reflecting the rich heritage and culture of Kashan. This rug is soft, smooth, and durable, adding a touch of luxury and beauty to any space.', tiles:{Pile: "Real Silk", Warp: 'Real Silk', Construction: "Hand knotted"}},
            { id: 'Q200', image: ['/Q200.png', '/Q201.png', '/Q202.png'], description: 'Qismat - Q200', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 11760, "6'x9'":26460, "8'x10'": 39200,"9'x12'": 52920}, detailedDescription:'This is a handknotted Kashan designed rug made with pure silk, a symbol of Persian craftsmanship and elegance. The rug has a blue field and a gold border, with a central medallion and a floral pattern. The rug is smooth, soft, and durable, adding a touch of luxury and beauty to any space.', tiles:{Pile: "Real Silk", Warp: 'Real Silk', Construction: "Hand knotted"}},
            { id: 'Q300', image: ['/Q300.png', '/Q301.png','/Q302.png'], description: 'Qismat - Q300', sizes: ["4'x6'", "6'x9'","8'x10'","9'x12'"],  prices:{"4'x6'": 11760, "6'x9'":26460, "8'x10'": 39200,"9'x12'": 52920}, detailedDescription:'This is a handknotted Kashmiri designed rug made with purest silk, a symbol of elegance and craftsmanship. The rug has a beige border with intricate designs in black, gold, and white, inspired by the rich culture and heritage of Kashmir. The center of the rug is divided into 40 squares, each with a unique design in gold, black, and white. The rug is smooth, soft, and durable, adding a touch of luxury and beauty to any space.', tiles:{Pile: "Real Silk", Warp: 'Real Silk', Construction: "Hand knotted"}},
           
        ]
    }, 
    {
        id: 'mixbag',
        name: 'mixbag',
        description: 'Bonus designs',
        products: [
            { id: "X100", image: ['/X100.jpg'], description: 'MixBag - X100', sizes: ["8'x10'"],  prices:{ "8'x10'": 5600}, detailedDescription:'This is a handknotted rug made with wool, a cozy and durable choice for your home. The rug has a dark blue background with intricate orange and red patterns, creating a striking contrast. The rug has a border with a geometric pattern and a center with 12 unique designs.',tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'X200', image: ['/X200.jpg'], description: 'MixBag - X200',sizes: ["8'x10'"],  prices:{ "8'x10'": 5600}, detailedDescription:'This handknotted rug is made with wool, a cozy and durable material for your home. The rug has a blue background with a brown border, and a repeating pattern of geometric shapes in white and brown.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"} },
            { id: 'X300', image: ['/X300.jpg'], description: 'MixBag - X300', sizes: ["9'x12'"],  prices:{"9'x12'": 7560}, detailedDescription:'Add a touch of elegance and warmth to your home with this handknotted Persian rug. This rug is made of 100% wool and features a stunning red and brown color scheme with intricate geometric and floral patterns. The rug has a large central diamond motif and a wide border that showcase the craftsmanship and quality of this piece.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'X400', image: ['/X400.jpg'], description: 'MixBag - X400', sizes: ["9'x12'"],  prices:{"9'x12'": 7560}, detailedDescription:'Bring home a piece of history with this traditional Persian rug. This rug is handknotted from 100% wool and has a rich red and gold color scheme. The rug features a repeating pattern of small geometric shapes and floral motifs that create a stunning visual effect. The rug has a thick gold border for adding warmth and elegance to any room in your house.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"} },
            { id: 'X500', image: ['/X500.jpg'], description: 'MixBag - X500', sizes: ["9'x12'"],  prices:{"9'x12'": 10800}, detailedDescription: 'This oriental rug is a masterpiece of handknotted craftsmanship and beauty. It has a beige background with a large, intricate, medallion design in the center. The medallion is surrounded by a border of smaller, repeating patterns in blue, brown, and gold. The rug is made of 100% wool and has a fringe on two sides.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'X600', image: ['/X600.jpg'], description: 'MixBag - X600', sizes: ["8'x10'"],  prices:{"8'x10'": 5600}, detailedDescription:'This traditional oriental rug is a beautiful addition to any home. It is handknotted from 100% wool and has a red border with intricate designs. The center of the rug is navy blue with a repeating geometric pattern in blown and green.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"} },
            { id: 'X700', image: ['/X700.jpg'], description: 'MixBag - X700',sizes: ["8'x10'","9'x12'"],  prices:{ "8'x10'": 5600,"9'x12'": 7560}, detailedDescription:'Add a touch of elegance to your home with this handknotted oriental rug. This rug is made of high-quality wool and features a red and gold color scheme that complements any decor. The rug has a beautiful border and a geometric pattern that creates a stunning visual effect. ', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}},
            { id: 'X800', image: ['/X800.jpg'], description: 'MixBag - X800', sizes: ["8'x10'"],  prices:{ "8'x10'": 5600}, detailedDescription:'This handknotted rug is a stunning piece of craftsmanship that will enhance any space. The rug is made of 100% wool and has a warm red-orange color with a striking contrast of black and gold. The rug features a geometric pattern that adds a modern touch to the traditional oriental design. The rug is 8x10 feet and ideal for large rooms.', tiles:{Pile: "New Zealand Wool", Warp: 'Indian Cotton', Construction: "Hand knotted"}}
        ]
    }
    ];
    
    // ... rest of the imports and code ...
    
    return (
        <div><Navbar/> 
        <div className="products-container">
    {themes.map(theme => (
        <div key={theme.id} id={theme.name} className="theme-section" data-theme-id={theme.id}>
        <h2>{theme.name}</h2>
        <h3>{theme.description}</h3>
        <div className="products-row">
            {theme.products.map(product => (
                <div className="product-item" key={product.id} onClick={() => {
                    setSelectedProduct({product: product, imageIndex: 0});
                    setSelectedSize(product.sizes[0]);
                }}>
                    <img src={product.image[0]} alt={product.description} />
                    <p>{product.description}</p>
                </div>
            ))}
        </div>
    </div>
    
))}

    
{selectedProduct.product && (
    <div 
        key={selectedProduct.product ? selectedProduct.product.id : 'no-product'} 
        className="enlarged-background" 
        onClick={() => setSelectedProduct({product: null, imageIndex: 0})}

    >
        <div className="enlarged" onClick={(e) => e.stopPropagation()}>
        <div className="close-btn" onClick={() => setSelectedProduct({product: null, imageIndex: 0})}>
                X
            </div>

        <div className="zoom-section">
    <div className="image-container"> {/* <-- New wrapping div */}
        <button 
            onClick={(e) => { 
                e.stopPropagation(); 
                setSelectedProduct(prev => ({
                    ...prev, 
                    imageIndex: (prev.imageIndex - 1 + prev.product.image.length) % prev.product.image.length
                })); 
            }}
            onMouseMove={(e) => e.stopPropagation()}
        >←</button>

        <img 
            src={selectedProduct.product.image[selectedProduct.imageIndex]} 
            alt={selectedProduct.product.description} 
            className="zoomable"
            ref={enlargedImgRef} 
        />

        <button 
            onClick={(e) => { 
                e.stopPropagation(); 
                setSelectedProduct(prev => ({
                    ...prev, 
                    imageIndex: (prev.imageIndex + 1) % prev.product.image.length
                })); 
            }}
            onMouseMove={(e) => e.stopPropagation()}
        >→</button>
    </div> {/* <-- End of new wrapping div */}
    <div className="zoom-lens" ref={zoomLens}></div>
</div>
            
            <div className="product-description">
                <h2>{selectedProduct.product.description}</h2>
                <div className="product-details-tiles">
                <div className="detail-tile">
                    <h4>Pile</h4>
                    <p>{selectedProduct.product.tiles.Pile}</p>
                </div>
                <div className="detail-tile">
                    <h4>Construction</h4>
                    <p>{selectedProduct.product.tiles.Construction}</p>
                </div>
                <div className="detail-tile">
                    <h4>Warp</h4>
                    <p>{selectedProduct.product.tiles.Warp}</p>
                </div>
            </div>
                <div className="custom-dropdown">
                    <select value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
                        {selectedProduct.product.sizes.map(size => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                    </div>
                
                    <p style={{ marginRight: '8%' }}>Price: ${selectedProduct.product.prices[selectedSize]}</p>

                <div className="product-detail">
                    
                    <p>{selectedProduct.product.detailedDescription}</p>
                    <a href="mailto:aditya@mayfairrugs.co?subject=Enquiry%20about%20" className="enquire-button">
                        Enquire Now
                    </a>
                </div>
            </div>

        </div>  
    </div>
)}
        
        </div>
        </div>
    );
}
export default Collections;
