import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import './AboutUs.css';
import ReactGA from 'react-ga';

function AboutUs() {
    const [showContent, setShowContent] = useState(false);
    const [darkenBackground, setDarkenBackground] = useState(false);

    const TRACKING_ID = "G-5B4418Y2D9"; 

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);

        const handleScroll = () => {
            const show = window.scrollY > 100;
            setShowContent(show);
            setDarkenBackground(show);
        };

        // Attach scroll event listener
        document.addEventListener('scroll', handleScroll);

        // Cleanup scroll event listener
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`about-us-container ${darkenBackground ? 'dark-background' : ''}`}>
            <Navbar />
            <h1 className={showContent ? 'fade-out' : ''}>About Us</h1>
            <div className={`about-us-content ${showContent ? 'show-content' : ''}`}>
                <p>Nestled in the heart of India, we are the custodians of an ancient legacy, 
                handcrafting exclusively hand-knotted rugs in the finest yarn. 
                With timeless perfection passed down through generations, 
                we weave not just threads but stories – 
                stories of tradition, elegance, and unrivaled luxury.</p>
                <p>We believe that a rug is more than just a piece of decor; 
                it's a masterpiece that transcends time, 
                enhancing the ambiance of your space and speaking to your discerning taste. 
                Each rug is meticulously crafted by the skilled hands of our artisans, 
                capturing the spirit of India's rich heritage and bringing it to homes across the world.</p>
                <p>Experience the legacy.<br /> 
                Embrace the luxury.</p>
                <h3>Welcome to <strong>Mayfair Rugs</strong></h3>
            </div>
        </div>
    );
}

export default AboutUs;
