import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="page-footer">
            <div className="footer-section">
                <h1>MAYFAIR RUGS</h1>              
                 <p>Head office:
                    18 Cairndore Park, Newtownards, 
                    Northern Ireland, BT238RH, United Kingdom</p>
                <p>Email: aditya@mayfairrugs.co <br></br>
                    Mob: +91 9958660416 | +44 07488 306961</p>
                
            </div>
            <div className="footer-section">
                <ul className="footer-links">
                    <li><a href="./about-us">About</a></li>
                    <li><a href="mailto:aditya@mayfairrugs.co"> Contact</a></li>
                    <li><a href="#link3">Refund and return policy</a></li>
                </ul>
            </div>
            <div className="footer-section-right">
                <h2> Lets weave legacy </h2>
                <p>Sign up for our mailing list and be the first to know about our products and special discounts</p>
                <a href="mailto:aditya@mayfairrugs.co?subject=Sign up Mayfair Rugs &body= Hi Aditya, I am interested in learning more about Mayfair Rugs products and discounts." className="footer-btn">Email Us</a>
            </div>
        </div>
    );
}

export default Footer;
