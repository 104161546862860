import { NavLink } from 'react-router-dom';
import './Navbar.css'; 

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg py-6">
            <div className="container-fluid">
                <NavLink className="navbar-brand custom-brand" to="/">MAYFAIR RUGS</NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav custom-links">
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active-nav-link"  exact to="/">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link"activeClassName="active-nav-link"  to="/about-us">About</NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink className="nav-link" activeClassName="active-nav-link" to="/themes">Collections</NavLink>
                        </li>
                        
                    </ul>
                    </div>
                </div>
            
        </nav>
    );
}
export default Navbar;